import AboutImageParagraphCard from "../../components/card/aboutImageParagraphCard"
import {Box} from "@chakra-ui/react"

const AboutNepal = () => {

    return (
        <>
        <Box>
            <AboutImageParagraphCard />
        </Box>
       
        </>
    )
}

export default AboutNepal