
const Apply = () => {
    
    return(
        <>
\        <div>
            <h1>This is a User Apply page.</h1>
        </div>
        </>
    )
}

export default Apply